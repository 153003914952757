import {
    Button,
    Card,
    Field,
    FlexGrid,
    stl,
    TextArea,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useState, useEffect } from 'react'

import type { SourceType } from '../../../types/database/source.type'
import { isEmpty } from '../../../utils/utils'
import { useAuth } from '../../AuthContext'
import { AlgoliaLoginVerify } from '../AlgoliaLoginVerify'

type Props = {
    source: SourceType
    integrationId: string
    loading: boolean
    updateSource: (source: SourceType) => void
    setShowBcSetup: (show: boolean) => void
}

export const ManageCustomFields: FunctionComponent<Props> = (props: Props) => {
    const { source, loading, updateSource, setShowBcSetup } = props
    const [customFields, setCustomFields] = useState(source.custom_fields ?? '')
    const { isPlatformAuthed, platform } = useAuth()
    const [error, setError] = useState<string | null>(null)

    const validateCustomFields = (value: string): string | null => {
        if (isEmpty(value)) {
            return null
        }

        if (/\n|\t|\r/.test(value)) {
            return 'Custom fields must not contain new lines, tabs, or return lines'
        }
        if (value.endsWith(',')) {
            return 'Custom fields must not end with a comma'
        }

        const fields = value.split(',')
        for (const field of fields) {
            if (/\s/.test(field)) {
                return 'Custom fields must not contain whitespace'
            }
            if (field.trim() === '') {
                return 'Custom field must not be empty'
            }
        }

        return null
    }

    useEffect(() => {
        if (customFields !== null) {
            const validationError = validateCustomFields(customFields)
            setError(validationError)
        }
    }, [customFields])

    return (
        <FlexGrid
            className="stl-w-full stl-h-full stl-flex stl-justify-center stl-min-h-screen stl-p-6 stl-app-bg"
            direction="column"
            alignment="center"
            spacing="md"
        >
            <Card fullBleed>
                <AlgoliaLoginVerify />
                <FlexGrid
                    direction="column"
                    alignment="center"
                    spacing="md"
                    className={stl`p-6`}
                >
                    <h1 className="stl-display-heading stl-text-grey-900">
                        Configure your custom fields
                    </h1>

                    <FlexGrid
                        className="stl-w-25 stl-w-full stl-mb-10 stl-p-2"
                        direction="column"
                        alignment="center"
                        spacing="md"
                    >
                        <Field
                            description={
                                <span>
                                    Comma separated list of custom fields to be
                                    indexed. If left empty no custom fields will
                                    be indexed. Attributes cannot contain new
                                    lines, white space, tabs and returns.
                                </span>
                            }
                        >
                            <TextArea
                                value={customFields ?? ''}
                                onChange={(e: any): void => {
                                    const value = e.target.value
                                    setCustomFields(value === '' ? null : value)
                                    setError(validateCustomFields(value))
                                }}
                                placeholder="Enter comma separated list of custom fields to index"
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </Field>
                    </FlexGrid>
                    <Button
                        className="stl-w-full stl-xenon-button"
                        size="large"
                        loading={loading}
                        disabled={
                            !isPlatformAuthed ||
                            isEmpty(platform) ||
                            error !== null
                        }
                        onClick={(): void => {
                            updateSource({
                                ...source,
                                custom_fields: customFields,
                            })
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        className="stl-w-full"
                        size="large"
                        onClick={(): void => {
                            setShowBcSetup(false)
                        }}
                    >
                        Cancel
                    </Button>
                </FlexGrid>
            </Card>
        </FlexGrid>
    )
}
