import type { IngestionClient } from '@algolia/ingestion'
import { ingestionClient } from '@algolia/ingestion'

import type { IntegrationType } from '../types/database/integration.type'

export default function getIngestionClient(
    integration: IntegrationType,
    apiKey: string
): IngestionClient {
    let host = import.meta.env.VITE_OBSERVABILITY_US_HOST
    // eslint-disable-next-line default-case
    switch (integration.region) {
        case 'us':
            host = import.meta.env.VITE_OBSERVABILITY_US_HOST
            break

        case 'de':
            host = import.meta.env.VITE_OBSERVABILITY_EU_HOST
            break
    }

    return ingestionClient(integration.algolia_app_id as string, apiKey, 'us', {
        hosts: [
            {
                accept: 'readWrite',
                protocol: 'https',
                url: host as string,
            },
        ],
        authMode: 'WithinHeaders',
        timeouts: {
            connect: 5000,
            read: 5000,
            write: 5000,
        },
    })
}
