import {
    Button,
    Card,
    Field,
    FlexGrid,
    stl,
    TextArea,
} from '@algolia/satellite'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import type { SourceType } from '../../../types/database/source.type'
import { isEmpty } from '../../../utils/utils'
import { useAuth } from '../../AuthContext'
import { AlgoliaLoginVerify } from '../AlgoliaLoginVerify'

type Props = {
    source: SourceType
    integrationId: string
    loading: boolean
    updateSource: (source: SourceType) => void
    setShowBcSetup: (show: boolean) => void
}

export const ManageAttributesToExclude: FunctionComponent<Props> = (
    props: Props
) => {
    const { source, loading, updateSource, setShowBcSetup } = props
    const [attributesToExclude, setAttributesToExclude] = useState(
        source.attributes_to_exclude ?? ''
    )
    const { isPlatformAuthed, platform } = useAuth()
    const [error, setError] = useState<string | null>(null)

    const validateAttributes = (value: string): string | null => {
        if (isEmpty(value)) {
            return null
        }

        if (value.endsWith(',')) {
            return 'Attributes to exclude must not end with a period'
        }

        const attributes = value.split(',')
        for (const attr of attributes) {
            if (attr.endsWith('.')) {
                return 'Attributes to exclude must not end with a period'
            }
            if (attr.toLowerCase() === '_tags') {
                return 'Attributes to exclude must not include "_tags"'
            }
            if (attr.toLowerCase() === 'objectid') {
                return 'Attributes to exclude must not include "objectID"'
            }
            if (/\n|\t|\r|\s/.test(attr)) {
                return 'Attributes to exclude must not contain new lines, tabs, return lines, or white spaces'
            }
            if (attr.trim() === '') {
                return 'Custom field must not be empty'
            }
        }
        return null
    }

    useEffect(() => {
        const validationError = validateAttributes(attributesToExclude)
        setError(validationError)
    }, [attributesToExclude])

    return (
        <FlexGrid
            className="stl-w-full stl-h-full stl-flex stl-justify-center stl-min-h-screen stl-p-6 stl-app-bg"
            direction="column"
            alignment="center"
            spacing="md"
        >
            <Card fullBleed>
                <AlgoliaLoginVerify />
                <FlexGrid
                    direction="column"
                    alignment="center"
                    spacing="md"
                    className={stl`p-6`}
                >
                    <h1 className="stl-display-heading stl-text-grey-900">
                        Configure your attributes to exclude
                    </h1>

                    <FlexGrid
                        className="stl-w-25 stl-w-full stl-mb-10 stl-p-2"
                        direction="column"
                        alignment="center"
                        spacing="md"
                    >
                        <Field
                            description={
                                <span>
                                    Enter a comma-separated list of attributes
                                    to exclude from the index. If left empty,
                                    all attributes will be indexed.
                                    Restrictions: Attributes cannot end with a
                                    period, include "_tags", "objectID", or
                                    contain new lines, white space, tabs and
                                    returns.
                                </span>
                            }
                        >
                            <TextArea
                                value={attributesToExclude ?? ''}
                                onChange={(e: any): void => {
                                    setAttributesToExclude(
                                        e.target.value === ''
                                            ? null
                                            : e.target.value
                                    )
                                }}
                                placeholder="Enter comma separated list of attributes to exclude from the index"
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </Field>
                    </FlexGrid>
                    <Button
                        className="stl-w-full stl-xenon-button"
                        size="large"
                        loading={loading}
                        disabled={
                            !isPlatformAuthed ||
                            isEmpty(platform) ||
                            error !== null
                        }
                        onClick={(): void => {
                            updateSource({
                                ...source,
                                attributes_to_exclude: attributesToExclude,
                            })
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        className="stl-w-full"
                        size="large"
                        onClick={(): void => {
                            setShowBcSetup(false)
                        }}
                    >
                        Cancel
                    </Button>
                </FlexGrid>
            </Card>
        </FlexGrid>
    )
}
