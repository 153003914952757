import React from 'react'
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom'

import { AlertProvider } from '../components/AlertContext'
import { useAuth } from '../components/AuthContext'
import { PlatformAuth } from '../components/algolia'
import { ProductSync } from '../components/bigcommerce/productSync'
import { MaintenanceMode } from '../components/common/maintenanceMode'
import { NotFound404 } from '../components/common/notFound404'
import { Login } from '../components/login'

import { bigCommerceRoutes } from './bigCommerceRoutes'
import { commonRoutes } from './commonRoutes'
import { RequireAuth } from './requireAuth'

const Admin = React.lazy(
    (): any =>
        import(
            /* webpackChunkName: "admin" */
            '../components/admin'
        )
)

export const Router: React.FunctionComponent = () => {
    const { isAdmin, isPlatformAuthed, setIsPlatformAuthed } = useAuth()
    let router = createBrowserRouter([
        {
            path: '/',
            element: <Navigate to={'/integrations'} replace />,
        },

        ...commonRoutes(),
        ...bigCommerceRoutes(),

        {
            path: '/admin/*',
            element: isAdmin ? (
                <RequireAuth>
                    <Admin />
                </RequireAuth>
            ) : (
                <Navigate to={'/*'} replace />
            ),
        },

        /* route used only by platform algolia authentication callback */
        {
            path: '/auth',
            element: (
                <AlertProvider>
                    <PlatformAuth
                        isPlatformAuthed={isPlatformAuthed}
                        setIsPlatformAuthed={setIsPlatformAuthed}
                        cookieAllowed={Boolean(navigator.cookieEnabled)}
                    />
                </AlertProvider>
            ),
        },

        {
            path: '/login',
            element: <Login />,
        },

        {
            path: '/maintenance-mode',
            element: <MaintenanceMode />,
        },

        {
            path: '/bigcommerce/product-sync',
            element: <ProductSync />,
        },

        /* Since the 404 route is a wildcard, it should be last */
        {
            path: '*',
            element: <NotFound404 />,
        },
    ])

    if (import.meta.env.VITE_MAINTENANCE_MODE === 'true') {
        router = createBrowserRouter([
            {
                path: '/*',
                element: <MaintenanceMode />,
            },
        ])
    }
    return <RouterProvider router={router} />
}
