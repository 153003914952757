import {
    Button,
    Card,
    CardHeader,
    Field,
    FlexGrid,
    Input,
    stl,
    Toggle,
} from '@algolia/satellite'
import * as Sentry from '@sentry/react'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Edit } from 'react-feather'

import BigCommerceService from '../../../services/bigCommerce/bigCommerceService'
import SourceService from '../../../services/database/sourceService'
import EntityService from '../../../services/theming/entitiesService'
import InstantsearchService from '../../../services/theming/instantsearchService'
import type {
    CategoriesObject,
    CategoriesPatch,
    CategoryIdsObject,
} from '../../../types/bigCommerce/categories.type'
import type { CategoriesMetafieldData } from '../../../types/bigCommerce/categoriesMetafield.type'
import type { BcChannel } from '../../../types/bigCommerce/channelsRespone.type'
import type { Entity } from '../../../types/database/entities.type'
import type {
    InstantSearch,
    InstantSearchConfig as InstantSearchConfigType,
    InstantSearchFacet,
    InstantSearchSortOrder,
} from '../../../types/database/instantSearch.type'
import type { IntegrationType } from '../../../types/database/integration.type'
import type { InstantsearchDefaultCode } from '../../../types/theming/instantsearchDefaultCode.type'
import type { Versioning } from '../../../types/theming/versioning.type'
import {
    chunkEntriesByPayloadSize,
    processCategoryChunks,
} from '../../../utils/chunkPayload'
import { findCategoryEntity } from '../../../utils/getCategoryEntity'
import { isEmpty } from '../../../utils/utils'
import { useAlert } from '../../AlertContext'
import { EditCodeModal } from '../../common/editCodeModal'
import { FacetOptionLimit } from '../../common/facetOptionLimit'
import { IsDistinctCheckbox } from '../../common/isDistinctCheckbox'
import { RegionsDropdown } from '../../common/regionsDropdown'
import { ShowOutOfStockCheckbox } from '../../common/showOutOfStockCheckbox'
import { SourceDropdown } from '../../common/sourceDropdown'
import { CodeUpdateAvailableBadge } from '../../common/theming/codeUpdateAvailableBadge'
import { FacetList } from '../../common/theming/facetList'
import { SortOrdering } from '../../common/theming/sortOrdering'
import { Categories } from '../categories'

import { ApplyToThemeConfirm } from './applyToThemeConfirm'

type Props = {
    currentChannel: BcChannel | undefined
    currentIntegration: IntegrationType | null | undefined
    currentInstantSearch: InstantSearch | undefined
    currentCategories: Entity
    setCurrentInstantSearch: (instantSearch: InstantSearch) => void
    setCurrentCategories: (categories: Entity) => void
    isFromAdminView: () => boolean
    isMultiChannel: boolean
}

export const InstantSearchConfig: FunctionComponent<Props> = (props): any => {
    const {
        currentIntegration,
        currentChannel,
        currentInstantSearch,
        currentCategories,
        setCurrentInstantSearch,
        setCurrentCategories,
        isFromAdminView,
        isMultiChannel,
    } = props

    const [newInstantSearch, setInstantSearch] = useState<
        InstantSearch | undefined
    >(currentInstantSearch)
    const [isLoading, setIsLoading] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [isCategoryChanged, setCategoryChanged] = useState(false)
    const [isCategoryIdsChanged, setIsCategoryIdsChanged] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [defaultCode, setDefaultCode] = useState<
        InstantsearchDefaultCode | undefined
    >()
    const [versioning, setVersioning] = useState<Versioning | undefined>()
    const { showErrorAlert, showSuccessAlert } = useAlert()
    const [editableCode, setEditableCode] = useState<string | undefined>()
    const [latestCode, setLatestCode] = useState<string | undefined>()
    const [isEditorOpen, setIsEditorOpen] = useState(false)
    const [editorDefaultCode, setEditorDefaultCode] = useState<
        string | undefined
    >()
    const [editorVersion, setEditorVersion] = useState<string>('1.01')
    const [editorTitle, setEditorTitle] = useState('Instantsearch CSS')
    const [editorSave, setEditorSave] = useState<(type: string) => void>(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (type: string) => {}
    )
    const [editorLanguage, setEditorLanguage] = useState('css')
    const [replicaNames, setReplicaNames] =
        useState<Array<{ value: string; label: string }>>()
    const [facetableAttributes, setFacetableAttributes] =
        useState<Array<{ value: string; label: string }>>()
    const [errors, setErrors] = useState<any>({
        search_form_css_selector: '',
        current_source: '',
        region: '',
        search_form_attribute: '',
        bc_page_name: '',
        bc_page_url: '',
        category_region: '',
        category_css_selector: '',
    })
    const [selectedPlacementRegion, setSelectedPlacementRegion] =
        useState<string>('page_builder_content')
    const [newCategories, setNewCategories] =
        useState<Entity>(currentCategories)
    const [updatedCategoryFacets, setUpdatedCategoryFacets] = useState<
        InstantSearchFacet[]
    >([])
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false)
    const [isCategoryFacetChanged, setCategoryFacetChange] = useState(false)
    const [hasMainToggleChanged, setHasMainToggleChanged] =
        useState<boolean>(false)

    const [deletedCategoryIds, setDeletedCategoryIds] = useState<number[]>([])

    async function createCategoryEntity(
        instantSearchId: string
    ): Promise<Entity | boolean> {
        setIsLoading(true)
        try {
            const categoryIds = newCategories?.config?.category_ids ?? {}
            const entries = Object.entries(categoryIds)
            const chunkedCategoryIds = chunkEntriesByPayloadSize(entries)

            const payload = {
                ...newCategories,
                instantsearch_id: instantSearchId,
                config: {
                    ...newCategories?.config,
                    isEnabled: newCategories?.config?.isEnabled ?? false,
                    category_ids:
                        Object.fromEntries(chunkedCategoryIds.shift() || []) ??
                        {},
                },
            }

            const entityResponse = await EntityService.createEntity(
                currentIntegration?.id as string,
                payload
            )

            const finalCategoryResponse = await processCategoryChunks(
                chunkedCategoryIds,
                currentIntegration?.id as string,
                {
                    ...newCategories,
                    id: entityResponse.id,
                    instantsearch_id: entityResponse.instantsearch_id,
                }
            )

            setNewCategories(finalCategoryResponse)
            setCurrentCategories(finalCategoryResponse)
            setHasMainToggleChanged(false)
            return finalCategoryResponse
        } catch (error) {
            showErrorAlert(error, 'Unable to create Category settings')
            Sentry.captureException(error)
            return false
        } finally {
            setIsChanged(false)
            setIsLoading(false)
        }
    }
    async function createInstantSearch(): Promise<boolean> {
        setIsLoading(true)
        try {
            const instantSearchResponse =
                await InstantsearchService.createInstantsearchSettings(
                    currentIntegration?.id as string,
                    currentChannel?.id as number,
                    {
                        ...(newInstantSearch as InstantSearch),
                        is_enabled: false,
                        config: {
                            ...(newInstantSearch?.config as InstantSearchConfigType),
                            placement_region:
                                newInstantSearch?.config?.placement_region ??
                                'page_builder_content',
                            hits_per_page:
                                newInstantSearch?.config?.hits_per_page ?? '15',
                        },
                    }
                )
            await createCategoryEntity(instantSearchResponse.id as string)
            setCurrentInstantSearch(instantSearchResponse)
            showSuccessAlert('InstantSearch settings saved', 'Success')
        } catch (error: any) {
            showErrorAlert(
                error.message,
                'Unable to create InstantSearch settings'
            )
            Sentry.captureException(error)
            return false
        } finally {
            setIsLoading(false)
            setIsChanged(false)
        }
        return true
    }

    async function updateInstantSearchSettings(
        instantSearchSettings: InstantSearch
    ): Promise<boolean> {
        setIsLoading(true)
        try {
            const instantSearchResponse =
                await InstantsearchService.updateInstantsearchSettings(
                    currentIntegration?.id as string,
                    currentChannel?.id as number,
                    currentInstantSearch?.id as string,
                    instantSearchSettings as InstantSearch
                )
            if (
                instantSearchResponse.is_enabled ===
                currentInstantSearch?.is_enabled
            ) {
                showSuccessAlert('InstantSearch settings updated', 'Success')
            }
            setCurrentInstantSearch(instantSearchResponse)
            setIsChanged(false)
        } catch (error: any) {
            if (error === 'Page name already in use') {
                setErrors({
                    ...errors,
                    bc_page_name: 'Page name already in use',
                })
            } else {
                showErrorAlert('Unable to update InstantSearch settings', error)
            }

            if (error === 'Page url already in use') {
                setErrors({
                    ...errors,
                    bc_page_url: 'Page url already in use',
                })
            } else {
                showErrorAlert('Unable to update InstantSearch settings', error)
            }

            Sentry.captureException(error)
            return false
        } finally {
            setIsLoading(false)
        }
        return true
    }

    async function toggleInstantSearch(is_enabled: boolean): Promise<void> {
        setIsLoading(true)
        try {
            let instantSearchResponse: InstantSearch

            if (is_enabled) {
                instantSearchResponse =
                    await InstantsearchService.applyInstantSearchToTheme(
                        currentIntegration?.id as string,
                        currentChannel?.id as number,
                        currentInstantSearch?.id as string
                    )
            } else {
                instantSearchResponse =
                    await InstantsearchService.removeInstantSearchFromTheme(
                        currentIntegration?.id as string,
                        currentChannel?.id as number,
                        currentInstantSearch?.id as string
                    )
            }
            let updatedEntity
            if (instantSearchResponse.entities) {
                updatedEntity = findCategoryEntity(
                    instantSearchResponse?.entities
                )
            }
            if (updatedEntity) {
                setNewCategories(updatedEntity)
                setCurrentCategories(updatedEntity)
            }
            setCurrentInstantSearch(instantSearchResponse)
            setInstantSearch(instantSearchResponse)
            setIsModalOpen(false)
            showSuccessAlert(
                `InstantSearch has been ${
                    is_enabled ? 'applied to' : 'removed from'
                } your theme`,
                'Success'
            )
            setIsChanged(false)
        } catch (error: any) {
            if (error === 'Page name already in use') {
                setErrors({
                    ...errors,
                    bc_page_name: 'Page name already in use',
                })
            } else if (error.includes('"html":"is too long')) {
                showErrorAlert(
                    `Unable to ${
                        is_enabled
                            ? 'apply InstantSearch to'
                            : 'remove InstantSearch from'
                    } theme`,
                    error
                )
            } else {
                showErrorAlert(
                    `Unable to ${
                        is_enabled
                            ? 'apply InstantSearch to'
                            : 'remove InstantSearch from'
                    } theme`,
                    error
                )
            }

            Sentry.captureException(error)
        } finally {
            setIsModalOpen(false)
            setIsLoading(false)
            setIsChanged(false)
        }
    }

    const validate = (): boolean => {
        let isValid = true
        const newErrors = {
            search_form_css_selector: '',
            current_source: '',
            region: '',
            search_form_attribute: '',
            bc_page_name: '',
            bc_page_url: '',
            category_region: '',
            category_css_selector: '',
        }
        if (!newInstantSearch?.config?.bc_page_name) {
            newErrors.bc_page_name = 'Page name is required'
            isValid = false
        }
        if (!newInstantSearch?.config?.bc_page_url) {
            newErrors.bc_page_url = 'Page url is required'
            isValid = false
        }
        if (!newInstantSearch?.config?.search_form_css_selector) {
            newErrors.search_form_css_selector =
                'Search form CSS selector is required'
            isValid = false
        }
        if (!newInstantSearch?.config?.current_source) {
            newErrors.current_source = 'InstantSearch Source is required'
            isValid = false
        }
        if (!selectedPlacementRegion) {
            newErrors.region = 'Placement Region is required'
            isValid = false
        }
        if (!newInstantSearch?.config?.search_form_attribute) {
            newErrors.search_form_attribute =
                'Search form attribute is required'
            isValid = false
        }
        if (
            !newCategories?.config?.placement_region &&
            newCategories?.config?.isEnabled
        ) {
            newErrors.category_region = 'Category placement region is required'
            isValid = false
        }
        if (
            !newCategories?.config?.css_selector &&
            newCategories?.config?.isEnabled
        ) {
            newErrors.category_css_selector =
                'Category CSS selector is required'
            isValid = false
        }
        setErrors(newErrors)
        return isValid
    }

    function shouldEnableSave(
        currentState: InstantSearchConfigType | undefined,
        newState: InstantSearchConfigType | undefined,
        excludedKeys: string[] = []
    ): boolean {
        if (currentState === undefined || newState === undefined) {
            return currentState === newState
        }

        const newKeys = Object.keys(newState).filter(
            (key) => !excludedKeys.includes(key)
        )

        for (const key of newKeys) {
            if ((newState as any)[key] !== (currentState as any)[key]) {
                return true
            }
        }

        return false
    }

    useEffect(() => {
        if (
            shouldEnableSave(
                currentInstantSearch?.config,
                newInstantSearch?.config,
                [
                    'widget_id',
                    'placement_id',
                    'script_id',
                    'page_id',
                    'active_widget_version_id',
                ]
            )
        ) {
            setIsChanged(true)
        }

        const formHasErrors = !Object.values(errors).every(
            (value) => value === ''
        )

        if (formHasErrors) {
            validate()
        }
    }, [newInstantSearch?.config, currentInstantSearch?.config])

    function handleToggle(is_enabled: boolean): void {
        if (is_enabled) {
            setIsModalOpen(true)
        } else {
            toggleInstantSearch(is_enabled)
        }
    }

    async function handleSave(): Promise<void> {
        try {
            // If the user has InstantSearch then update it otherwise create it
            if (typeof currentInstantSearch?.id !== 'undefined') {
                await updateInstantSearchSettings(
                    newInstantSearch as InstantSearch
                )
            } else {
                await createInstantSearch()
                return
            }

            // If we already have InstantSearch settings and we do not have categories
            // Create a category entity
            if (
                typeof currentInstantSearch?.id !== 'undefined' &&
                typeof currentCategories?.id === 'undefined'
            ) {
                await createCategoryEntity(currentInstantSearch?.id as string)
                return
            }

            // Category has been changed
            if (
                newCategories.config &&
                (isCategoryChanged || isCategoryIdsChanged)
            ) {
                await partialCategoryUpdate(newCategories.config)
            }

            // Category toggle has been changed, apply or remove category entity only if InstantSearch is enabled
            if (hasMainToggleChanged) {
                await handleMainCategoryToggle(
                    newCategories?.config?.isEnabled as boolean
                )
            }
        } catch (error) {
            showErrorAlert('Unable to save InstantSearch settings', error)
        }
    }

    function handleCodeEdit(type: string): void {
        const javascriptVersion = newInstantSearch?.config?.javascript_version
        const cssVersion = newInstantSearch?.config?.css_version

        switch (type) {
            case 'css':
                setEditableCode(
                    typeof newInstantSearch === 'undefined' ||
                        typeof newInstantSearch.css === 'undefined' ||
                        newInstantSearch.css === null
                        ? defaultCode?.css
                        : (newInstantSearch.css as string)
                )
                setEditorDefaultCode(defaultCode?.css)
                setEditorSave((): ((field: string, field1: string) => void) => {
                    return (css: string, versionBeingSaved: string): void => {
                        InstantsearchService.updateCss(
                            newInstantSearch?.integration_id as string,
                            currentChannel?.id as number,
                            newInstantSearch?.id as string,
                            {
                                css,
                                config: { css_version: versionBeingSaved },
                            }
                        )
                            .then(() => {
                                setInstantSearch({
                                    ...(newInstantSearch as InstantSearch),
                                    css,
                                    config: {
                                        ...newInstantSearch?.config,
                                        css_version: versionBeingSaved,
                                    },
                                })

                                showSuccessAlert(
                                    'Successfully updated instantsearch CSS'
                                )
                            })
                            .catch((err) => {
                                if (
                                    err.includes(
                                        'The CSS or JavaScript is too long'
                                    )
                                ) {
                                    showErrorAlert(err)
                                } else {
                                    showErrorAlert(
                                        'Failed updating instantsearch JavaScript'
                                    )
                                }
                            })
                    }
                })
                if (typeof cssVersion === 'undefined' && versioning) {
                    setEditorVersion(versioning?.latestVersion)
                }

                if (typeof cssVersion !== 'undefined') {
                    setEditorVersion(cssVersion)
                }
                setLatestCode(versioning?.latestCss)
                setEditorLanguage('css')
                setEditorTitle('Instantsearch CSS')
                setIsEditorOpen(true)
                break

            case 'javascript':
                setEditableCode(
                    typeof newInstantSearch === 'undefined' ||
                        typeof newInstantSearch.javascript === 'undefined' ||
                        newInstantSearch.javascript === null
                        ? defaultCode?.javascript
                        : (newInstantSearch.javascript as string)
                )
                setEditorDefaultCode(defaultCode?.javascript)
                setEditorSave((): ((field: string, field1: string) => void) => {
                    return (
                        javascript: string,
                        versionBeingSaved: string
                    ): void => {
                        InstantsearchService.updateJavascript(
                            newInstantSearch?.integration_id as string,
                            currentChannel?.id as number,
                            newInstantSearch?.id as string,
                            {
                                javascript,
                                config: {
                                    javascript_version: versionBeingSaved,
                                },
                            }
                        )
                            .then(() => {
                                setInstantSearch({
                                    ...(newInstantSearch as InstantSearch),
                                    javascript,
                                    config: {
                                        ...newInstantSearch?.config,
                                        javascript_version: versionBeingSaved,
                                    },
                                })

                                showSuccessAlert(
                                    'Successfully updated instantsearch JavaScript'
                                )
                            })
                            .catch((err) => {
                                if (
                                    err.includes(
                                        'The CSS or JavaScript is too long'
                                    )
                                ) {
                                    showErrorAlert(err)
                                } else {
                                    showErrorAlert(
                                        'Failed updating instantsearch JavaScript'
                                    )
                                }
                            })
                    }
                })
                if (typeof javascriptVersion === 'undefined' && versioning) {
                    setEditorVersion(versioning?.latestVersion)
                }

                if (typeof javascriptVersion !== 'undefined') {
                    setEditorVersion(javascriptVersion)
                }
                setLatestCode(versioning?.latestJavascript)
                setEditorLanguage('javascript')
                setEditorTitle('Instantsearch JavaScript')
                setIsEditorOpen(true)
                break

            default:
        }
    }

    async function fetchDefaultCodeAndVersioning(): Promise<void> {
        const defaults = await InstantsearchService.getInstantsearchDefaultCode(
            currentIntegration?.id as string,
            Number(currentChannel?.id),
            currentInstantSearch?.id as string
        )
        setDefaultCode(defaults)

        const versions = await InstantsearchService.getInstantsearchVersioning(
            currentIntegration?.id as string,
            Number(currentChannel?.id),
            currentInstantSearch?.id as string
        )
        setVersioning(versions)

        if (
            typeof newInstantSearch?.css === 'undefined' ||
            typeof newInstantSearch?.javascript === 'undefined'
        ) {
            setInstantSearch({
                ...(newInstantSearch as InstantSearch),
                css: newInstantSearch?.css || defaults.css,
                javascript: newInstantSearch?.javascript || defaults.javascript,
                config: {
                    ...newInstantSearch?.config,
                    css_version:
                        newInstantSearch?.config?.css_version ||
                        versions.latestVersion,
                    javascript_version:
                        newInstantSearch?.config?.javascript_version ||
                        versions.latestVersion,
                },
            })
        }
    }

    async function fetchIndexData(): Promise<void> {
        const indexData = await SourceService.getIndexData(
            newInstantSearch?.integration_id as string,
            newInstantSearch?.config?.current_source as string
        )

        if (!isEmpty(indexData) || isEmpty(!indexData.facetableAttributes)) {
            const options = indexData?.facetableAttributes?.map(
                (attribute) => ({
                    value: attribute.value,
                    label: attribute.value,
                })
            )

            setFacetableAttributes(options)
        }

        if (!isEmpty(indexData) || isEmpty(!indexData.replicaNames)) {
            const names = indexData?.replicaNames?.map((replica) => ({
                value: replica,
                label: replica,
            }))

            setReplicaNames(names)
        }
    }

    function isToggleDisabled(): boolean {
        return (
            currentInstantSearch === undefined ||
            currentInstantSearch.id === undefined ||
            isLoading ||
            isFromAdminView()
        )
    }

    useEffect(() => {
        if (typeof defaultCode === 'undefined') {
            fetchDefaultCodeAndVersioning()
        }

        setInstantSearch(currentInstantSearch)
    }, [currentInstantSearch])

    useEffect(() => {
        if (typeof newInstantSearch?.config?.current_source !== 'undefined') {
            fetchIndexData()
        }
    }, [newInstantSearch?.config?.current_source])

    async function handleMainCategoryToggle(isEnabled: boolean): Promise<void> {
        if (!newCategories?.config) {
            return
        }

        setIsLoading(true)

        try {
            if (isEnabled && currentInstantSearch?.is_enabled) {
                await EntityService.applyEntity(
                    currentIntegration?.id as string,
                    newCategories as Entity
                )
            }

            if (!isEnabled && currentInstantSearch?.is_enabled) {
                await EntityService.removeEntity(
                    currentIntegration?.id as string,
                    newCategories as Entity
                )
            }

            await EntityService.patchEntity(
                currentIntegration?.id as string,
                newCategories as Entity,
                { config: { isEnabled } }
            )
        } catch (error) {
            showErrorAlert('Unable to update categories', error)
            Sentry.captureException(error)
        } finally {
            setIsLoading(false)
            setHasMainToggleChanged(false)
            setIsCategoryIdsChanged(false)
        }
    }

    async function partialCategoryIdUpdate(
        categoryId: number,
        categoryIdObject: CategoryIdsObject
    ): Promise<void> {
        try {
            const payload: CategoriesPatch = {
                config: {
                    category_ids: {
                        [categoryId]: {
                            ...categoryIdObject,
                        },
                    },
                },
                last_chunk: true,
            }

            const response = await EntityService.patchEntity(
                currentIntegration?.id as string,
                {
                    id: currentCategories.id,
                    instantsearch_id: currentCategories.instantsearch_id,
                } as Entity,
                payload
            )

            setNewCategories(response)
            setCurrentCategories(response)
            setHasMainToggleChanged(false)
            setIsCategoryIdsChanged(false)
            setCategoryChanged(false)
            showSuccessAlert('Successfully updated categories')
        } catch (error) {
            showErrorAlert('Unable to update category ID')
        }
    }

    async function partialCategoryUpdate(
        categoryConfig: CategoriesObject
    ): Promise<void> {
        setIsLoading(true)
        try {
            const payload: CategoriesPatch = {
                config: {
                    isEnabled: categoryConfig.isEnabled,
                    placement_region: categoryConfig.placement_region,
                    css_selector: categoryConfig.css_selector,
                },
            }

            const updatedCategories = await EntityService.patchEntity(
                currentIntegration?.id as string,
                {
                    id: currentCategories.id,
                    instantsearch_id: currentCategories.instantsearch_id,
                } as Entity,
                payload
            )

            if (isCategoryIdsChanged) {
                const entries = Object.entries(categoryConfig.category_ids)
                const chunkedCategoryIds = chunkEntriesByPayloadSize(entries)

                const finalCategoryResponse = await processCategoryChunks(
                    chunkedCategoryIds,
                    currentIntegration?.id as string,
                    updatedCategories,
                    deletedCategoryIds
                )
                setDeletedCategoryIds([])
                setNewCategories(finalCategoryResponse)
                setCurrentCategories(finalCategoryResponse)
                return
            }

            setNewCategories(updatedCategories)
            setCurrentCategories(updatedCategories)
        } catch (error) {
            showErrorAlert('Unable to update categories', error)
            Sentry.captureException(error)
        } finally {
            setIsLoading(false)
            setIsChanged(false)
        }
    }

    const handleCategoriesEntityUpdate = useCallback(
        (entity: Entity) => {
            setNewCategories((prev) => {
                const updatedCategoryIds = entity.config?.category_ids || {}

                return {
                    ...prev,
                    entity_type: 'category',
                    config: {
                        isEnabled:
                            entity.config?.isEnabled !== undefined
                                ? entity.config?.isEnabled
                                : prev?.config?.isEnabled || false,
                        placement_region:
                            entity.config?.placement_region !== undefined
                                ? entity.config?.placement_region
                                : prev?.config?.placement_region ||
                                  'category_below_header',
                        css_selector:
                            entity.config?.css_selector !== undefined
                                ? entity.config?.css_selector
                                : prev?.config?.css_selector || '.page',
                        category_ids: updatedCategoryIds,
                    },
                }
            })

            setIsChanged(true)
        },
        [setNewCategories, setIsChanged]
    )

    async function updateMetafield(
        selectedCategoryId: number,
        category: Entity,
        operation: string,
        categoryMetafieldData?: CategoriesMetafieldData
    ): Promise<void> {
        const updatedCategories = { ...category }
        let response
        let successMessage = ''
        try {
            setIsCategoryModalOpen(false)
            setCategoryFacetChange(false)

            switch (operation) {
                case 'update':
                    if (!categoryMetafieldData) {
                        return
                    }

                    await BigCommerceService.updateCategoryMetafield(
                        selectedCategoryId,
                        updatedCategories.config?.category_ids[
                            selectedCategoryId
                        ].metafieldId as number,
                        categoryMetafieldData
                    )
                    successMessage = 'Successfully updated category facet'
                    break
                case 'create':
                    if (!categoryMetafieldData || !updatedCategories.config) {
                        return
                    }

                    response = await BigCommerceService.createCategoryMetafield(
                        selectedCategoryId,
                        categoryMetafieldData
                    )

                    updatedCategories.config.category_ids[
                        selectedCategoryId
                    ].metafieldId = response.id

                    successMessage = 'Successfully created category facet'

                    break
                case 'delete':
                    response = await BigCommerceService.deleteCategoryMetafield(
                        selectedCategoryId,
                        updatedCategories.config?.category_ids[
                            selectedCategoryId
                        ].metafieldId as number
                    )
                    if (response && updatedCategories.config) {
                        delete updatedCategories.config.category_ids[
                            selectedCategoryId
                        ].metafieldId
                    }

                    successMessage = 'Successfully removed category facet'
                    break
                default:
            }

            await partialCategoryIdUpdate(
                selectedCategoryId,
                updatedCategories.config?.category_ids[
                    selectedCategoryId
                ] as CategoryIdsObject
            )

            if (successMessage !== '') {
                showSuccessAlert(successMessage)
            }
        } catch (error) {
            showErrorAlert(
                `Unable to update category metafield ${selectedCategoryId}`
            )
            Sentry.captureException(error)
        }
    }

    async function handleCategoryToggle(
        selectedCategoryId: number
    ): Promise<void> {
        try {
            if (!newCategories?.config || !currentInstantSearch?.id) {
                showErrorAlert('Unable to update category facets')
                return
            }

            const categoryItem =
                newCategories.config.category_ids[selectedCategoryId]

            if (categoryItem) {
                categoryItem.isEnabled = !categoryItem.isEnabled
                await partialCategoryIdUpdate(selectedCategoryId, categoryItem)
            }
        } catch (error) {
            showErrorAlert('Unable to update category toggle', error)
        }
    }

    async function handleCategoryFacetUpdate(
        selectedCategoryId: number
    ): Promise<void> {
        const updatedCategories = { ...newCategories }

        if (!updatedCategories?.config || !currentInstantSearch?.id) {
            showErrorAlert('Unable to update category facets')
            return
        }

        updatedCategories.config.category_ids[selectedCategoryId].facets =
            updatedCategoryFacets

        // If InstantSearch is not enabled then we should save the facet to our database
        // and not create a metafield
        // Or if the category is not enabled then we should not create a metafield on the store front
        // bulk category creation will handle it
        const isInstantSearchEnabled =
            currentInstantSearch?.is_enabled && currentInstantSearch?.id

        if (!isInstantSearchEnabled || !updatedCategories?.config?.isEnabled) {
            await partialCategoryIdUpdate(
                selectedCategoryId,
                updatedCategories.config.category_ids[selectedCategoryId]
            )
            setIsCategoryModalOpen(false)

            return
        }

        const categoryMetafieldData = {
            value: JSON.stringify(updatedCategoryFacets),
        }

        const isMetafieldDefined =
            typeof updatedCategories.config?.category_ids[selectedCategoryId]
                .metafieldId !== 'undefined'
        const facetsLength = updatedCategoryFacets.length

        try {
            if (isMetafieldDefined && facetsLength > 0) {
                await updateMetafield(
                    selectedCategoryId,
                    updatedCategories,
                    'update',
                    categoryMetafieldData
                )
                return
            }

            if (!isMetafieldDefined) {
                await updateMetafield(
                    selectedCategoryId,
                    updatedCategories,
                    'create',
                    categoryMetafieldData
                )
                return
            }

            if (isMetafieldDefined && facetsLength < 1) {
                await updateMetafield(
                    selectedCategoryId,
                    updatedCategories,
                    'delete'
                )
            }
        } catch (error) {
            showErrorAlert('Unable to update category facet')
            Sentry.captureException(error)
        }
    }

    useEffect(() => {
        if (isFromAdminView()) {
            setNewCategories(currentCategories)
        }
    }, [currentCategories])

    return (
        <>
            <EditCodeModal
                title={editorTitle}
                language={editorLanguage}
                editableCode={editableCode as string}
                isModalOpen={isEditorOpen}
                setIsModalOpen={setIsEditorOpen}
                defaultCode={editorDefaultCode as string}
                save={editorSave}
                currentVersion={editorVersion}
                latestVersion={versioning?.latestVersion as string}
                latestCode={latestCode as string}
                setEditableCode={setEditableCode}
                isFromAdminView={isFromAdminView}
            />
            <ApplyToThemeConfirm
                type={`Instantsearch`}
                isModalOpen={isModalOpen}
                isLoading={isLoading}
                setIsModalOpen={setIsModalOpen}
                applyToTheme={toggleInstantSearch}
            />
            <Card className={stl`w-900`} fullBleed>
                <CardHeader className={stl`px-4 mt-4 mb-2`}>
                    <h2 className={stl`display-heading`}>
                        InstantSearch - Results page powered by Algolia
                    </h2>
                </CardHeader>
                <FlexGrid className={stl`w-full px-4 mb-4`} distribution="fill">
                    <div style={{ width: '50%' }}>
                        <p>
                            Replace the default search page with Algolia's
                            InstantSearch, to provide your users with
                            instantaneous results, onscreen filtering (facets),
                            and other enhanced search features.
                        </p>
                        {currentInstantSearch?.config?.current_source ===
                            undefined && (
                            <p className={stl`mt-2`}>
                                <b>
                                    To enable InstantSearch for the first time,
                                    please configure the required settings.
                                </b>
                            </p>
                        )}
                    </div>
                    <div className={stl`flex justify-end`}>
                        <p className={stl`mr-2`}>
                            {currentInstantSearch?.is_enabled
                                ? 'Enabled'
                                : 'Disabled'}
                        </p>
                        <Toggle
                            checked={currentInstantSearch?.is_enabled}
                            disabled={isToggleDisabled()}
                            onChange={(event: any): void => {
                                handleToggle(event.target.checked)
                            }}
                        />
                    </div>
                </FlexGrid>

                <FlexGrid
                    className={stl`w-full bg-grey-100 p-6`}
                    direction="column"
                    spacing="lg"
                >
                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Page Name *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                className={stl`w-full`}
                                description={
                                    <span>
                                        BigCommerce page name that will be
                                        displayed on the search results page.
                                    </span>
                                }
                                state={{
                                    errors: [errors.bc_page_name],
                                    status: errors.bc_page_name
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <Input
                                    placeholder="Search Results"
                                    variant="small"
                                    value={
                                        newInstantSearch?.config?.bc_page_name
                                    }
                                    onChange={(event: any): void => {
                                        setInstantSearch({
                                            ...(newInstantSearch as InstantSearch),
                                            config: {
                                                ...(newInstantSearch?.config as InstantSearchConfigType),
                                                bc_page_name:
                                                    event.target.value,
                                            },
                                        })
                                    }}
                                />
                            </Field>
                        </div>
                    </FlexGrid>

                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Page URL *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                className={stl`w-full`}
                                description={
                                    <span>
                                        BigCommerce page url that will be used
                                        for Instantsearch page. Please ensure
                                        that no other
                                        <a
                                            href={
                                                isMultiChannel
                                                    ? `https://store-${currentIntegration?.config?.bcStoreHash}.mybigcommerce.com/manage/channel/${currentChannel?.id}/pages`
                                                    : `https://store-${currentIntegration?.config?.bcStoreHash}.mybigcommerce.com/manage/content/pages`
                                            }
                                            target="_blank"
                                        >
                                            {` pages `}
                                        </a>
                                        contain this URL.
                                    </span>
                                }
                                state={{
                                    errors: [errors.bc_page_url],
                                    status: errors.bc_page_url
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <Input
                                    placeholder="/view-search-results"
                                    variant="small"
                                    value={
                                        newInstantSearch?.config?.bc_page_url
                                    }
                                    onChange={(event: any): void => {
                                        setInstantSearch({
                                            ...(newInstantSearch as InstantSearch),
                                            config: {
                                                ...(newInstantSearch?.config as InstantSearchConfigType),
                                                bc_page_url: event.target.value,
                                            },
                                        })
                                    }}
                                />
                            </Field>
                        </div>
                    </FlexGrid>

                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Source *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                description={
                                    <span>
                                        Source used to display products in
                                        InstantSearch. This field is required.
                                    </span>
                                }
                                state={{
                                    errors: [errors.current_source],
                                    status: errors.current_source
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <SourceDropdown
                                    currentChannel={currentChannel}
                                    integration={currentIntegration}
                                    selectedSource={
                                        newInstantSearch?.config?.current_source
                                    }
                                    setSelectedSource={(
                                        current_source: string
                                    ): void => {
                                        setInstantSearch({
                                            ...(newInstantSearch as InstantSearch),
                                            config: {
                                                ...(newInstantSearch?.config as InstantSearchConfigType),
                                                current_source,
                                            },
                                        })
                                        setIsChanged(true)
                                    }}
                                />
                            </Field>
                        </div>
                    </FlexGrid>

                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Placement Region *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                description={
                                    <span>
                                        Placement region to place InstantSearch
                                        for your theme. This field is required.
                                    </span>
                                }
                                state={{
                                    errors: [errors.region],
                                    status: errors.region
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <RegionsDropdown
                                    channelId={currentChannel?.id as number}
                                    templateFile="pages/page"
                                    setSelectedPlacementRegion={(
                                        placement_region: string
                                    ): void => {
                                        setInstantSearch({
                                            ...(newInstantSearch as InstantSearch),
                                            config: {
                                                ...(newInstantSearch?.config as InstantSearchConfigType),
                                                placement_region,
                                            },
                                        })
                                        setSelectedPlacementRegion(
                                            placement_region
                                        )
                                    }}
                                    selectedPlacementRegion={
                                        newInstantSearch?.config
                                            ?.placement_region ??
                                        selectedPlacementRegion
                                    }
                                />
                            </Field>
                        </div>
                    </FlexGrid>

                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Number of Products shown
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                description={
                                    <span>
                                        Number of products to show in search.
                                        Defaults to 15.
                                    </span>
                                }
                            >
                                <Input
                                    type="number"
                                    variant="small"
                                    placeholder="15"
                                    value={
                                        newInstantSearch?.config
                                            ?.hits_per_page ?? 15
                                    }
                                    onChange={(event: any): void => {
                                        setInstantSearch({
                                            ...(newInstantSearch as InstantSearch),
                                            config: {
                                                ...(newInstantSearch?.config as InstantSearchConfigType),
                                                hits_per_page:
                                                    event.target.value,
                                            },
                                        })
                                    }}
                                />
                            </Field>
                        </div>
                    </FlexGrid>

                    <FacetOptionLimit
                        facetOptionLimit={
                            newInstantSearch?.config?.facet_options_limit ?? 35
                        }
                        setFacetOptionLimit={(limit: number): void => {
                            setInstantSearch({
                                ...(newInstantSearch as InstantSearch),
                                config: {
                                    ...(newInstantSearch?.config as InstantSearchConfigType),
                                    facet_options_limit: limit,
                                },
                            })
                        }}
                    />

                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Search Form CSS Selector *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                className={stl`w-full`}
                                description={
                                    <span>
                                        CSS Selector for the search form. You
                                        might have to change this value to an
                                        element selector that works for your
                                        theme. This field is required and case
                                        sensitive.
                                    </span>
                                }
                                state={{
                                    errors: [errors.search_form_css_selector],
                                    status: errors.search_form_css_selector
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <Input
                                    variant="small"
                                    placeholder="form.form[data-quick-search-form]"
                                    value={
                                        newInstantSearch?.config
                                            ?.search_form_css_selector
                                    }
                                    onChange={(event: any): void => {
                                        setInstantSearch({
                                            ...(newInstantSearch as InstantSearch),
                                            config: {
                                                ...(newInstantSearch?.config as InstantSearchConfigType),
                                                search_form_css_selector:
                                                    event.target.value,
                                            },
                                        })
                                    }}
                                />
                            </Field>
                        </div>
                    </FlexGrid>

                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Search Form Attribute *
                        </h2>
                        <div className={stl`w-400 flex justify-start`}>
                            <Field
                                className={stl`w-full`}
                                description={
                                    <>
                                        <span>
                                            HTML attribute from the above search
                                            form that stores the redirection
                                            route to the search results page.
                                            This field is required and case
                                            sensitive.
                                        </span>
                                    </>
                                }
                                state={{
                                    errors: [errors.search_form_attribute],
                                    status: errors.search_form_attribute
                                        ? 'invalid'
                                        : 'default',
                                }}
                            >
                                <Input
                                    placeholder="data-url"
                                    variant="small"
                                    value={
                                        newInstantSearch?.config
                                            ?.search_form_attribute
                                    }
                                    onChange={(event: any): void => {
                                        setInstantSearch({
                                            ...(newInstantSearch as InstantSearch),
                                            config: {
                                                ...(newInstantSearch?.config as InstantSearchConfigType),
                                                search_form_attribute:
                                                    event.target.value,
                                            },
                                        })
                                    }}
                                />
                            </Field>
                        </div>
                    </FlexGrid>

                    <ShowOutOfStockCheckbox
                        showOutofStock={
                            newInstantSearch?.config?.show_out_of_stock_items ??
                            false
                        }
                        setShowOutofStock={(
                            showOutofStockItmes: boolean
                        ): void => {
                            setInstantSearch({
                                ...(newInstantSearch as InstantSearch),
                                config: {
                                    ...(newInstantSearch?.config as InstantSearchConfigType),
                                    show_out_of_stock_items:
                                        showOutofStockItmes,
                                },
                            })
                        }}
                    />

                    <IsDistinctCheckbox
                        isDistinct={
                            newInstantSearch?.config?.is_distinct ?? false
                        }
                        setIsDistinct={(isDistinct: boolean): void => {
                            setInstantSearch({
                                ...(newInstantSearch as InstantSearch),
                                config: {
                                    ...(newInstantSearch?.config as InstantSearchConfigType),
                                    is_distinct: isDistinct,
                                },
                            })
                        }}
                    />

                    <SortOrdering
                        replicaNames={
                            replicaNames?.filter((item) => {
                                if (newInstantSearch?.sort_orders) {
                                    return !newInstantSearch?.sort_orders
                                        .map(
                                            (usedItem): string =>
                                                usedItem.replicaName
                                        )
                                        .includes(item.value)
                                }
                                return []
                            }) ?? []
                        }
                        currentSource={
                            newInstantSearch?.config?.current_source as string
                        }
                        integration={currentIntegration as IntegrationType}
                        sortOrders={newInstantSearch?.sort_orders ?? []}
                        setIsChanged={setIsChanged}
                        onSortOrderChange={(
                            updatedSortOrder: InstantSearchSortOrder[]
                        ): void => {
                            setInstantSearch({
                                ...(newInstantSearch as InstantSearch),
                                sort_orders: updatedSortOrder,
                            })
                            setIsChanged(true)
                        }}
                        fetchIndexData={fetchIndexData}
                    />

                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Facets
                        </h2>
                        <div className={stl`w-400 flex justify-start flex-col`}>
                            <FacetList
                                facetableAttributes={
                                    facetableAttributes?.filter((item) => {
                                        if (newInstantSearch?.facets) {
                                            return !newInstantSearch?.facets
                                                .map(
                                                    (usedItem): string =>
                                                        usedItem.attribute
                                                )
                                                .includes(item.value)
                                        }
                                        return []
                                    }) ?? []
                                }
                                isNewInstantSearch={
                                    currentInstantSearch === undefined
                                }
                                isFromAdminView={isFromAdminView}
                                setIsChanged={setIsChanged}
                                facets={newInstantSearch?.facets ?? []}
                                onFacetChange={(
                                    updatedFacets: InstantSearchFacet[]
                                ): void => {
                                    setInstantSearch({
                                        ...(newInstantSearch as InstantSearch),
                                        facets: updatedFacets,
                                    })
                                }}
                                fetchIndexData={fetchIndexData}
                            />
                            <p
                                className={stl`display-caption text-grey-600 mt-2`}
                            >
                                Facets are filters that allow users to narrow
                                down their search results. You can add, remove,
                                and reorder facets.
                            </p>
                        </div>
                    </FlexGrid>

                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Edit CSS
                        </h2>
                        <div className={stl`w-400 justify-start`}>
                            <Button
                                disabled={isEmpty(newInstantSearch?.id)}
                                onClick={(): void => {
                                    handleCodeEdit('css')
                                }}
                                startIcon={Edit}
                                className="stl-mb-10"
                                size="large"
                                variant="neutral"
                            >
                                Edit
                            </Button>
                            <CodeUpdateAvailableBadge
                                version={newInstantSearch?.config?.css_version}
                                versioning={versioning as Versioning}
                            />
                            <p className="stl-display-caption stl-text-grey-600 stl-mt-2">
                                Add or edit the CSS used for the Instantsearch
                                widget
                            </p>
                        </div>
                    </FlexGrid>

                    <FlexGrid className={stl`w-full`}>
                        <h2
                            className={stl`display-subheading`}
                            style={{
                                width: '250px',
                            }}
                        >
                            Edit JavaScript
                        </h2>
                        <div className={stl`w-400 justify-start`}>
                            <Button
                                disabled={isEmpty(newInstantSearch?.id)}
                                onClick={(): void => {
                                    handleCodeEdit('javascript')
                                }}
                                startIcon={Edit}
                                className="stl-mb-10"
                                size="large"
                                variant="neutral"
                            >
                                Edit
                            </Button>
                            <CodeUpdateAvailableBadge
                                version={
                                    newInstantSearch?.config?.javascript_version
                                }
                                versioning={versioning as Versioning}
                            />
                            <p className="stl-display-caption stl-text-grey-600 stl-mt-2">
                                Add or edit the JavaScript used for the
                                Instantsearch widget.
                            </p>
                        </div>
                    </FlexGrid>

                    <Categories
                        setCategoriesEntity={handleCategoriesEntityUpdate}
                        categoriesEntity={newCategories}
                        errors={errors}
                        currentInstantSearch={currentInstantSearch}
                        facetableAttributes={facetableAttributes}
                        setIsCategoryIdsChanged={setIsCategoryIdsChanged}
                        setCategoryChanged={setCategoryChanged}
                        handleCategoryFacetUpdate={handleCategoryFacetUpdate}
                        handleCategoryToggle={handleCategoryToggle}
                        updatedCategoryFacets={updatedCategoryFacets}
                        setUpdatedCategoryFacets={setUpdatedCategoryFacets}
                        setIsCategoryModalOpen={setIsCategoryModalOpen}
                        isCategoryModalOpen={isCategoryModalOpen}
                        isFromAdminView={isFromAdminView}
                        setCategoryFacetChange={setCategoryFacetChange}
                        isCategoryFacetChanged={isCategoryFacetChanged}
                        hasMainToggleChanged={hasMainToggleChanged}
                        setHasMainToggleChanged={setHasMainToggleChanged}
                        fetchIndexData={fetchIndexData}
                        setDeletedCategoryIds={setDeletedCategoryIds}
                    />

                    <FlexGrid
                        className={stl`w-full pr-25 flex justify-end`}
                        distribution="trailing"
                    >
                        <Button
                            variant="primary"
                            disabled={!isChanged || isFromAdminView()}
                            onClick={(): void => {
                                if (validate()) {
                                    handleSave()
                                } else {
                                    showErrorAlert(
                                        'Please fill in all required InstantSearch fields',
                                        'Error'
                                    )
                                }
                            }}
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </FlexGrid>
                </FlexGrid>
            </Card>
        </>
    )
}
